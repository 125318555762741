.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  margin: 5px;
  padding: 20px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.03);
}
.modalTitle {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}
.modalTable {
  border-collapse: collapse;
  width: 100%;
}
.modalTable td {
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ddd;
  padding: 10px;
  text-transform: capitalize;
  color: rgb(15, 14, 14);
}
.modalTable th {
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  border: 1px solid #ddd;
  color: rgb(27, 27, 27);
}
.editButton {
  position: absolute;
  bottom: 20px;
  right: 10px;
  font-weight: 500;
  font-size: 18px;
  border: none;
  line-height: 25px;
  padding: 5px 10px;
  background-color: #e8397c;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}
.editButton:hover {
  background-color: #fff;
  color: #e8397c;
  outline: 1px solid #e8397c;
}
.modalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.clientEditButton {
  font-weight: 500;
  font-size: 18px;
  border: none;
  line-height: 25px;
  padding: 5px 10px;
  background-color: #e8397c;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}
.clientEditButton:hover {
  background-color: #fff;
  color: #e8397c;
  outline: 1px solid #e8397c;
}
.ReactModal__Overlay {
  background-color: rgba(27, 27, 27, 0.5) !important;
}

@media (min-width: 280px) and (max-width: 768px) {
  .ReactModal__Content {
    width: 80% !important;
    overflow-x: scroll;
  }
  .modalTable td {
    font-size: 12px;
    font-weight: 400;
  }
  .modalTable th {
    font-weight: 600;
    font-size: 10px;
    padding-right: 4px;
  }
}
