.clientList {
  flex: 4;
  padding: 20px;
  padding-top: 5px;
  max-height: calc(100vh - 115px);
}
.clientList .MuiDataGrid-cell {
  text-transform: capitalize !important;
}
.userListDelete {
  color: red;
  cursor: pointer;
}
.userListView {
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: rgb(27, 105, 229);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}
.addClientButton .MuiButtonBase-root {
  position: absolute !important;
  bottom: 65px !important;
  right: 30px !important;
  border-radius: 100% !important;
  background-color: rgb(27, 105, 229);
}
.captionSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-bottom: 8px;
}
.searchInput {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid rgb(183, 180, 180);
  color: #74757a;
  border-radius: 3px;
}
.search {
  border: none;
}
.search:focus {
  border: none;
  outline: none;
}
.searchInput:focus {
  border: none;
  outline: 2px solid rgb(183, 180, 180);
}
.searchBtn {
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: rgb(27, 105, 229);
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.caption {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 8px;
  color: rgb(27, 27, 27);
}
.dataGrid {
  min-height: calc(100vh - 115px);
}
.MuiDataGrid-root {
  min-height: calc(100vh - 100px) !important;
}
@media (min-width: 280px) and (max-width: 768px) {
  .clientList {
    height: 100vh;
    min-height: 100vh;
  }
  .captionSearchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .dataGrid {
    min-height: calc(100vh - 100px);
  }
  .MuiDataGrid-root {
    min-height: calc(100vh - 100px) !important;
  }
}
