.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoImg {
  max-width: "100%";
  max-height: "100%";
}
.logoText {
  font-weight: 700;
  font-size: 20px;
  color: #ea4c89;
}
.loginText {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
  color: #1f2937;
}
.signInText {
  margin-top: -20px;
  line-height: 2rem;
  color: #9c9a9a;
}
/*MEDIA QUERIES */

@media (min-width: 360px) and (max-width: 768px) {
  .headerWrapper {
    margin-top: 20px;
  }
}
@media (min-width: 280px) and (max-width: 320px) {
  .headerWrapper {
    margin-top: 20px;
  }
  .signInText {
    padding-left: 5px;
    font-size: 13px;
  }
  .loginText {
    font-size: 1.2rem;
  }
}
