.printWrapper {
  min-height: 100vh;
  display: block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 25px;
  width: 600px;
  height: 150px;
  gap: 8px;
}
.logoWrapper img {
  object-fit: contain;
  width: 70%;
  height: 100%;
}
.logoTextt {
  font-family: "Times New Roman", Times, serif;
  font-size: 22px;
  font-weight: 500;
  color: rgb(4, 109, 9);
}
.clientInfoWrapper {
  width: 95%;
  padding: 20px;
  margin-top: 15px;
}
.hotelInfoWrapper {
  width: 95%;
  padding: 20px;
  margin-top: 15px;
}
.payDetails {
  padding: 20px;
  margin-top: 20px;
}
.infoTable {
  border-collapse: collapse;
  width: 100%;
}
.infoTable Th {
  font-weight: 500;
  font-size: 16px;
  padding-right: 10px;
  padding-left: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  border: 1px solid #ddd;
  color: rgb(27, 27, 27);
}
.infoTable td {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #ddd;
  padding-left: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
}
.printTableCaption {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 8px;
  color: rgb(0, 0, 0);
}
.termsConditions {
  width: 95%;
  margin-top: 20px;
  padding: 20px;
}
.termsConditions p {
  font-weight: 500;
  font-size: 20px;
}
.termsConditions ul li {
  line-height: 1.5;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}
.regards {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}
@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}
