.addClient {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addClientContainer {
  background-color: #fff;
  border-radius: 6px;
  width: 70%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0d9d9;
}
.addClientTitle {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: stretch;
}

.formComponentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.formItemWrapper{
  display: flex;
  gap:10px;
  width: 100%;
}
.formItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-control {
  font-size: 14px;
  color: #74757a;
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.form-error {
  border: 1px solid #dc2626;
}
.form-control:focus {
  outline: none;
  border: 1px solid#f28db3;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(233, 103, 222, 0.05);
  box-shadow: 0px 0px 15px 3px rgba(233, 103, 122, 0.05);
}
.formErrorMsg {
  flex: 1;
  margin-top: 4px;
  margin-left: 2px;
  color: #dc2626;
  text-align: left;
  font-size: 14px;
}
.formItem .MuiFormControl-root {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  padding: 3px 6px !important;
  background-color: #fff !important;
}
.MuiInputLabel-root {
  padding: 10px !important;
}
.formItem .MuiFormControl-root .MuiInputBase-input {
  color: #74757a !important;
  border-bottom: none !important;
  padding: 6px !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.customControl {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.custom-label {
  color: #74757a;
  font-size: 14px;
  margin-left: 8px;
}
#cab {
  width: 18px;
  height: 20px;
  background: white;
  border-radius: 5px;
  border: 2px solid #c5c2c2;
}
.lblMsg {
  color: #111827;
  margin-left: 15px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.primary {
  font-weight: 600;
  border: none;
  background-color: #ea4c89;
  padding: 0 16px;
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  border-radius: 6px;
  margin-top: 20px;
  width: 175px;
  cursor: pointer;
  float: right;
}
.primary:hover {
  background-color: #e8397c;
}

@media (min-width: 280px) and (max-width: 768px) {
  .addClient {
    max-width: 100%;
    flex: 1;
    border-radius: 1px;
  }
  .addClientContainer {
    width: calc(100vw - 20px) !important;
    padding: 10px 10px;
    margin: 0;
  }

  .formComponentWrapper {
    flex-direction: column;
    margin-bottom: 0;
  }

  .addClientTitle {
    padding: 10px;
  }
  .formContainer {
    width: calc(100% - 10px) !important;
    padding-left: 5px;
    padding-right: 5px;
    gap: 10px;
  }

  .costContainer {
    width: calc(100% - 20px) !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .primary {
    margin-right: 5px;
  }
  .form-control {
    border: none;
    font-size: 14px;
    color: #74757a;
    padding: 1rem 1rem;
    outline: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  .form-error {
    border: none;
    outline: 1px solid #dc2626;
  }
  .form-control:focus {
    border: none;
    outline: 1px solid#f28db3;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(233, 103, 222, 0.05);
    box-shadow: 0px 0px 15px 3px rgba(233, 103, 122, 0.05);
  }
}
