.loginWrapper {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: Center;
  flex-direction: column;
  gap: 20px;
  background: radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(329, 100%, 95%, 1) 100%,
    hsla(352, 100%, 94%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(329, 100%, 95%, 1) 100%,
    hsla(352, 100%, 94%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(329, 100%, 95%, 1) 100%,
    hsla(352, 100%, 94%, 1) 100%
  );
}
.forgotPassword {
  display: flex;
  align-items: center;
  justify-items: end;
}
/*MEDIA QUERIES */

@media (min-width: 280px) and (max-width: 768px) {
  .loginWrapper {
    justify-content: flex-start;
    min-width: 100%;
  }
}
