.menuSidebar {
  width: 60%;
  max-width: 260px;
  position: fixed;
  min-height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: whitesmoke;
  border-right: 1px solid #e0d9d9;
}
.closeIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
