.tableData {
  margin-top: 20px;
  flex: 4;
  padding: 20px;
  padding-left: 5px;
  /* border: 1px solid #e0d9d9; */
}
.clientTable {
  border-collapse: collapse;
  width: 100%;
}
.tableCaption {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 8px;
  color: rgb(27, 27, 27);
}
.clientTable th {
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  border: 1px solid #ddd;
  color: rgb(27, 27, 27);
}
.clientTable td {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ddd;
  padding: 10px;
  text-transform: capitalize;
  color: rgb(15, 14, 14);
}

@media (min-width: 280px) and (max-width: 768px) {
  .tableData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    margin-top: 0;
    padding: 10px;
  }
  .clientTable td {
    font-size: 12px;
    font-weight: 400;
  }
  .clientTable th {
    font-weight: 600;
    font-size: 10px;
    padding-right: 4px;
  }
}
