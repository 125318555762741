.sidebar {
  flex: 1;
  min-height: 100vh;
  width: 220px;
  max-width: 250px;
  position: sticky;
  background-color: #fff;
  /* z-index: 1; */
  border-right: 1px solid #e0d9d9;
}
.sidebarWrapper {
  padding: 10px 20px;
  color: #adadad;
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 150px;
  width: 150px;
  gap: 5px;
  margin: auto;
  justify-content: center;
}
.logo-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.sidebarMenu {
  display: flex;
  align-items: center;
  width: 180px;
  margin-top: 20px;
}
.sidebarList {
  list-style: none;
}
.sidebarListItem {
  font-weight: 400;
  padding-top: 3px;
  font-size: 16px;
  color: #292929;
  padding-bottom: 25px;
  cursor: pointer;
}
.links {
  text-decoration: none;
}
.icons {
  color: #292929;
  font-size: 12px;
}
.nav-links {
  display: flex;
  gap: 10px;
}
.nav-links:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}
.logoOutWrapper {
  padding: 15px 25px 0 25px;
  background-color: #fff2f2;
  border-radius: 15px;
  display: flex;
  position: absolute;
  gap: 5px;
  bottom: 15px;
  color: #ff1e1e;
}
.logoOutWrapper:hover {
  background-color: #ffd6d6;
  transition: all 0.1s;
}
.logoutLink {
  padding-bottom: 18px;
  font-weight: 500;
  font-size: 18px;
}

@media (min-width: 280px) and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
