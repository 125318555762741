.formWrapper {
  width: 400px;
}
.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  gap: 20px;
}
.login-form-control {
  font-size: 14px;
  color: #74757a;
  padding: 1rem 1rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.login-form-control:focus {
  outline: none;
  border: 2px solid#f28db3;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(233, 103, 222, 0.05);
  box-shadow: 0px 0px 15px 5px rgba(233, 103, 122, 0.05);
}
.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-btn .primary {
  line-height: 48px;
  width: 160px;
}
.login-btn .primary:hover {
  background-color: #fff;
  color: #ea4c89;
  outline: 2px solid#f28db3;
}

/*MEDIA QUERIES */

@media (min-width: 360px) and (max-width: 768px) {
  .formWrapper {
    width: 20rem;
  }
  .loginFormContainer {
    padding: 5px;
  }
  .loginFormContainer .formItem{
    width: calc(100% - 20px);
    padding: 0 20px;
  }
}
@media (min-width: 280px) and (max-width: 320px) {
  .formWrapper {
    width: 17rem;
  }
  .loginFormContainer {
    padding: 5px;
  }
  .loginFormContainer .formItem{
    width: calc(100% - 20px);
    padding: 0 20px;
  }
}
