.featured {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.featuredItem {
  display: flex;
  flex-direction: column;
  justify-self: center;
  flex: 1;
  margin: 0px 20px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 12px -10px rgba(63, 25, 25, 0.6);
  box-shadow: 0px 0px 12px -10px rgba(0, 0, 0, 0.6);
}

.featuredTitle {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.featuredMoneyContainer {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.featuredMoney {
  font-weight: 600;
  font-size: 18px;
  color: #005a39;
}
.featuredSub {
  font-size: 10px;
  color: #5d4c32ff;
}
@media (min-width: 280px) and (max-width: 768px) {
  .featured {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 103px));
    gap: 7px;
    align-items: stretch;
    justify-content: center;
  }
  .featuredTitle {
    font-size: 10px;
  }
  .featuredMoney {
    font-size: 16px;
  }
  .featuredItem {
    padding: 10px;
    margin: 0;
    /* outline: 1px solid rgba(68, 68, 68, 0); */
  }

  .featuredSub {
    font-size: 8px;
  }
}
