.home {
  flex: 4;
}
.userClientData {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.calendarComponent {
  flex: 1;
  margin-top: 20px;
  padding: 20px;
  padding-right: 5px;
}
@media (min-width: 280px) and (max-width: 768px) {
  .userClientData {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }
  .e-calendar {
    min-width: 90vw !important;
  }
}
