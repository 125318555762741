.topbar {
  width: 100%;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 99;
}
.topbarWrapper {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbarLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbarRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu {
  display: none;
}
.logo-text {
  display: none;
}
.backdrop {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.25s;
}

@media (min-width: 280px) and (max-width: 768px) {
  .topbar {
    background-color: #fff;
  }
  .topbarWrapper {
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: 2px solid whitesmoke;
  }
  .topbarLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }
  .menuIcon {
    height: 0.9em !important;
  }
  .logo-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    font-weight: 600;
    font-family: cursive;
    letter-spacing: 1.3px;
    color: #ed4980;
    font-size: 20px;
  }
  .topbarLeft {
    justify-content: flex-start;
  }
  .logo {
    font-size: 16px;
    line-height: 20px;
  }
  .topbarRight {
    display: none;
  }
  .img-time {
    display: none;
  }
}
