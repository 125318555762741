.hotelDetails {
  width: 100% !important;
}
.MuiFormControl-root {
  border: none !important;
  padding: 0 !important;
}
.MuiInputBase-input {
  color: rgb(65, 62, 62) !important;
  padding: 10px !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}
.MuiFormControl-root input:focus {
  border-bottom: 2px solid #f28db3 !important;
}
.MuiFormControl-fullWidth {
  margin-right: 5px !important;
}
